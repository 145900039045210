export default [
  {
    path: "/",
    name: "signIn",
    component: () => import("@/views/auth/SignIn.vue"),
  },
  {
    path: "/sign-up",
    component: () => import("@/views/auth/SignUp.vue"),
  },
  {
    path: "/advertiser/sign-up",
    component: () => import("@/views/auth/AdvertiserSignUp.vue"),
  },
  {
    path: "/verify/:id",
    component: () => import("@/views/auth/Verify.vue"),
  },
  {
    path: "/forgot-password",
    component: () => import("@/views/auth/ForgotPassword.vue"),
  },
  {
    path: "/reset-password/:id",
    component: () => import("@/views/auth/ResetPassword.vue"),
  },
];
