export default [
  {
    id: 1,
    name: "Dashboard",
    link: "/advertiser/dashboard",
    icon: '<i class="ki-outline ki-home-2 fs-2"></i>',
    roles: ["advertiser"],
  },
  {
    id: 2,
    name: "Review Tasks",
    link: "/advertiser/review",
    icon: '<i class="fa fa-tasks fs-2"></i>',
    roles: ["advertiser"],
  },
  {
    id: 8,
    name: "Tasks",
    link: "",
    icon: '<i class="ki-outline ki-profile-user fs-1"></i>',
    roles: ["advertiser"],
    children: [
      {
        id: 9,
        name: "Campaigns",
        link: "/advertiser/campaigns",
      },
      {
        id: 10,
        name: "List",
        link: "/advertiser/tasks",
      },
    ],
  },
  {
    id: 9,
    name: "Transactions",
    link: "/advertiser/transactions",
    icon: '<i class="fa fa-dollar-sign fs-2"></i>',
    roles: ["advertiser"],
  },
  {
    id: 9,
    name: "Payment Methods",
    link: "/advertiser/payment-methods",
    icon: '<i class="fa fa-credit-card fs-2"></i>',
    roles: ["advertiser"],
  },
  {
    id: 12,
    name: "Settings",
    link: "",
    icon: '<i class="ki-outline ki-setting-2 fs-2"></i>',
    roles: ["advertiser"],
    children: [
      {
        id: 13,
        name: "Email Providers",
        link: "/advertiser/settings/email-providers",
      },
      {
        id: 14,
        name: "Email Templates",
        link: "/advertiser/settings/email-templates",
      },
    ],
  },
];
