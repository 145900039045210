<template>
  <div id="app">
    <RouterView :key="$route.path" />
  </div>
</template>

<script>
import { RouterView } from "vue-router";

export default {
  name: "app",
  components: {
    RouterView,
  },
};
</script>

<style lang="scss">
@import "bootstrap-icons/font/bootstrap-icons.css";
@import "apexcharts/dist/apexcharts.css";
@import "quill/dist/quill.snow.css";
@import "animate.css";
@import "sweetalert2/dist/sweetalert2.css";
@import "nouislider/dist/nouislider.css";
@import "@fortawesome/fontawesome-free/css/all.min.css";
@import "socicon/css/socicon.css";
@import "line-awesome/dist/line-awesome/css/line-awesome.css";
@import "dropzone/dist/dropzone.css";
@import "@vueform/multiselect/themes/default.css";
@import "prism-themes/themes/prism-shades-of-purple.css";
@import "element-plus/dist/index.css";

@import "@/assets/keenicons/duotone/style.css";
@import "@/assets/keenicons/outline/style.css";
@import "@/assets/keenicons/solid/style.css";

@import "@/assets/sass/plugins";
@import "@/assets/sass/style";

@import "vue-multiselect/dist/vue-multiselect.css";
@import "@/assets/card.scss";
#app {
  display: contents;
}

body[data-theme="dark"] .multiselect__tags {
  background: #1b1c22;
  color: var(--bs-gray-700);
  border: none;
}
body[data-theme="dark"] .multiselect__input {
  background: #1b1c22;
  border: none;
}
body[data-theme="dark"] .multiselect {
  background: #1b1c22;
  border-color: var(--bs-gray-300);
}
body[data-theme="dark"] .multiselect__content-wrapper {
  background: #1b1c22;
  color: var(--bs-gray-700);
}
body[data-theme="dark"] .multiselect__single {
  background: #1b1c22;
}
body[data-theme="dark"] .multiselect__option--selected {
  background: #1b1c22;
}
</style>
