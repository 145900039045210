export function getAuthToken() {
  return localStorage.getItem("auth_token");
}

export function setAuthToken(token) {
  localStorage.setItem("auth_token", token);
}

export function removeAuthToken() {
  localStorage.removeItem("auth_token");
}

export function getAuthUser() {
  const user = localStorage.getItem("user");
  if (user) {
    return JSON.parse(user);
  }
  return null;
}

export function setAuthUser(user) {
  localStorage.setItem("user", JSON.stringify(user));
}

export function removeAuthUser() {
  localStorage.removeItem("user");
}
