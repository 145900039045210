import MainLayout from "@/layouts/MainLayout.vue";
export default [
  {
    path: "/advertiser/dashboard",
    component: MainLayout,
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        component: () =>
          import("@/views/advertiser/dashboard/AdminDashboard.vue"),
      },
    ],
  },

  {
    path: "/advertiser/tasks",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/advertiser/tasks/TasksList.vue"),
      },
    ],
  },
  {
    path: "/advertiser/campaigns",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () =>
          import("@/views/advertiser/campaigns/CampaignList.vue"),
      },
    ],
  },

  {
    path: "/advertiser/transactions",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () =>
          import("@/views/advertiser/transactions/TransactionsList.vue"),
      },
    ],
  },
  {
    path: "/advertiser/payment-methods",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () =>
          import("@/views/advertiser/payment-methods/PaymentMethodsList.vue"),
      },
    ],
  },
  {
    path: "/advertiser/campaigns/:id",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () =>
          import("@/views/advertiser/campaigns/CampaignOverview.vue"),
      },
    ],
  },

  {
    path: "/advertiser/tasks/:id",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/advertiser/tasks/TasksShow.vue"),
      },
    ],
  },
  {
    path: "/advertiser/review",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/advertiser/user-tasks/TasksList.vue"),
      },
    ],
  },
  {
    path: "/advertiser/settings/email-templates",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () =>
          import(
            "@/views/advertiser/settings/email-templates/EmailTemplatesList.vue"
          ),
      },
    ],
  },
  {
    path: "/advertiser/settings/email-templates/create",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () =>
          import(
            "@/views/advertiser/settings/email-templates/CreateEmailTemplate.vue"
          ),
      },
    ],
  },
  {
    path: "/advertiser/settings/email-templates/:id",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () =>
          import(
            "@/views/advertiser/settings/email-templates/EditEmailTemplate.vue"
          ),
      },
    ],
  },
  {
    path: "/advertiser/settings/email-providers",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () =>
          import(
            "@/views/advertiser/settings/email-providers/EmailProvidersList.vue"
          ),
      },
    ],
  },
];
