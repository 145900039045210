import { createRouter, createWebHistory } from "vue-router";
import { getAuthToken } from "@/stores/auth.js";

import accountRoutes from "./account.js";
import authRoutes from "./auth.js";
import adminRoutes from "./admin.js";
import managerRoutes from "./manager.js";
import employeesRoutes from "./employees.js";
import advertiserRoutes from "./advertiser.js";
import errorsRoutes from "./errors.js";

const router = createRouter({
  mode: "history",
  history: createWebHistory(),
  routes: [
    ...authRoutes,
    ...accountRoutes,
    ...adminRoutes,
    ...employeesRoutes,
    ...managerRoutes,
    ...advertiserRoutes,
    ...errorsRoutes,
  ],
});

router.beforeEach((to, from, next) => {
  const token = getAuthToken();
  // next({
  //   path: '/unauthorized',
  //   query: { redirect: to.fullPath }
  // });

  if (to.meta.requiresAuth && !token) {
    next("/");
  } else {
    next();
  }
});

export default router;
