import MainLayout from "@/layouts/MainLayout.vue";

export default [
  {
    path: "/me/profile",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/account/AccountSettings.vue"),
      },
    ],
  },
  {
    path: "/me/password",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/account/ChangePassword.vue"),
      },
    ],
  },
];
