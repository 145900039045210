export default [
  {
    id: "e_1",
    name: "Home",
    link: "/tasks",
    icon: '<i class="fa fa-home fs-2"></i>',
    roles: ["employee"],
  },
  {
    id: "e_2",
    name: "My Tasks",
    link: "/my/tasks",
    icon: '<i class="fa fa-tasks fs-2"></i>',
    roles: ["employee"],
  },
  {
    id: "e_3",
    name: "My Emails",
    link: "/emails",
    icon: '<i class="fa fa-envelope fs-2"></i>',
    roles: ["employee"],
  },
  {
    id: "e_4",
    name: "My Earnings",
    link: "/my/earnings",
    icon: '<i class="fa fa-dollar-sign fs-2"></i>',
    roles: ["employee"],
  },
  {
    id: "e_5",
    name: "Withdraws",
    link: "/my/salaries",
    icon: '<i class="fa fa-wallet fs-2"></i>',
    roles: ["employee"],
  },
  {
    id: "e_6",
    name: "Support",
    link: "/my/tickets",
    icon: '<i class="fa fa-question-circle fs-2"></i>',
    roles: ["employee"],
  },
];
