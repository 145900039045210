import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/index.js";
import VueApexCharts from "vue3-apexcharts";
import VueSweetalert2 from "vue-sweetalert2";
import VueDatePicker from "@vuepic/vue-datepicker";
import ButtonComponent from "@/components/ButtonComponent";
import "@vuepic/vue-datepicker/dist/main.css";
import mitt from "mitt";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/vue-tel-input.css";

const app = createApp(App);

const emitter = mitt();
app.config.globalProperties.emitter = emitter;
app.use(VueSweetalert2);
app.use(VueApexCharts);
app.use(VueTelInput);
app.use(router);
app.component("VueDatePicker", VueDatePicker);
app.component("v-button", ButtonComponent);
app.mount("#app");
