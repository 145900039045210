export default [
  {
    id: 1,
    name: "Dashboard",
    link: "/admin/dashboard",
    icon: '<i class="ki-outline ki-home-2 fs-2"></i>',
    roles: ["admin"],
  },
  {
    id: 2,
    name: "Advertisers",
    link: "/admin/advertisers",
    icon: '<i class="ki-outline ki-tag fs-1"></i>',
    roles: ["admin"],
  },
  {
    id: 3,
    name: "Users",
    link: "/admin/users",
    icon: '<i class="ki-outline ki-profile-user fs-1"></i>',
    roles: ["admin"],
  },
  {
    id: 4,
    name: "Teams",
    link: "/admin/teams",
    icon: '<i class="ki-outline ki-parcel-tracking fs-1"></i>',
    roles: ["admin"],
  },
  {
    id: 5,
    name: "Groups",
    link: "/admin/groups",
    icon: '<i class="fa fa-users"></i>',
    roles: ["admin"],
  },
  {
    id: 6,
    name: "Employees",
    link: "/admin/employees",
    icon: '<i class="ki-outline ki-gift fs-2"></i>',
    roles: ["admin"],
  },
  {
    id: 7,
    name: "Finance",
    link: "/admin/balance",
    icon: '<i class="ki-outline ki-gift fs-2"></i>',
    roles: ["admin"],
  },
  {
    id: 8,
    name: "Transactions",
    link: "/admin/transactions",
    icon: '<i class="fa fa-dollar-sign fs-2"></i>',
    roles: ["admin"],
  },
  {
    id: 9,
    name: "Tasks",
    link: "",
    icon: '<i class="ki-outline ki-profile-user fs-1"></i>',
    roles: ["admin"],
    children: [
      {
        id: 10,
        name: "Campaigns",
        link: "/admin/campaigns",
      },
      {
        id: 11,
        name: "List",
        link: "/admin/tasks",
      },
    ],
  },
  {
    id: 12,
    name: "Reports",
    link: "/admin/reports",
    icon: '<i class="fa fa-line-chart fs-2"></i>',
    roles: ["admin"],
  },
  {
    id: 13,
    name: "Settings",
    link: "",
    icon: '<i class="ki-outline ki-setting-2 fs-2"></i>',
    roles: ["admin"],
    children: [
      {
        id: 14,
        name: "Email Providers",
        link: "/admin/settings/email-providers",
      },
      {
        id: 15,
        name: "Email Templates",
        link: "/admin/settings/email-templates",
      },
      {
        id: 16,
        name: "Configs",
        link: "/admin/settings/configs",
      },
    ],
  },
  {
    id: 17,
    name: "Support",
    link: "/admin/tickets",
    icon: '<i class="ki-outline ki-briefcase fs-1"></i>',
    roles: ["admin"],
  },
];
