import MainLayout from "@/layouts/MainLayout.vue";
export default [
  {
    path: "/admin/dashboard",
    component: MainLayout,
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        component: () => import("@/views/admin/dashboard/AdminDashboard.vue"),
      },
    ],
  },
  {
    path: "/admin/advertisers",
    component: MainLayout,
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        component: () => import("@/views/admin/advertiser/AdvertiserList.vue"),
      },
    ],
  },
  {
    path: "/admin/advertisers/:id",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () =>
          import("@/views/admin/advertiser/AdvertiserOverview.vue"),
      },
    ],
  },
  {
    path: "/admin/moderation/advertisers",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () =>
          import("@/views/admin/advertiser/AdvertiserModerationList.vue"),
      },
    ],
  },
  {
    path: "/admin/advertisers/:id/tasks",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/admin/advertiser/AdvertiserTasks.vue"),
      },
    ],
  },
  {
    path: "/admin/users",
    component: MainLayout,
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        component: () => import("@/views/admin/users/UsersList.vue"),
      },
    ],
  },
  {
    path: "/admin/users/:id",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/admin/users/UsersShow.vue"),
      },
    ],
  },
  {
    path: "/admin/tasks",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/admin/tasks/TasksList.vue"),
      },
    ],
  },
  {
    path: "/admin/campaigns",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/admin/campaigns/CampaignList.vue"),
      },
    ],
  },
  {
    path: "/admin/campaigns/:id",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/admin/campaigns/CampaignOverview.vue"),
      },
    ],
  },

  {
    path: "/admin/tasks/:id",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/admin/tasks/TasksShow.vue"),
      },
    ],
  },
  {
    path: "/admin/teams",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/admin/teams/TeamsList.vue"),
      },
    ],
  },
  {
    path: "/admin/groups",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/admin/groups/List.vue"),
      },
    ],
  },
  {
    path: "/admin/teams/:id",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/admin/teams/TeamUsers.vue"),
      },
    ],
  },
  {
    path: "/admin/teams/:id/users",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/admin/teams/TeamUsers.vue"),
      },
    ],
  },
  {
    path: "/admin/reports",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/admin/reports/Reports.vue"),
      },
    ],
  },
  {
    path: "/admin/teams/:id/settings",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/admin/teams/TeamSettings.vue"),
      },
    ],
  },
  {
    path: "/admin/transactions",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () =>
          import("@/views/admin/transactions/TransactionsList.vue"),
      },
    ],
  },
  {
    path: "/admin/review/tasks",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/admin/tasks/TasksReviewList.vue"),
      },
    ],
  },

  {
    path: "/admin/user-tasks",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/admin/user-tasks/TasksList.vue"),
      },
    ],
  },
  {
    path: "/admin/notification/salaries",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/admin/balance/SalariesList.vue"),
      },
    ],
  },
  {
    path: "/admin/employees/unassigned",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () =>
          import("@/views/admin/employees/EmployeesUnassignedList.vue"),
      },
    ],
  },
  {
    path: "/admin/employees",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/admin/employees/EmployeesList.vue"),
      },
    ],
  },
  {
    path: "/admin/employees/:id",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/admin/employees/EmployeeOverview.vue"),
      },
    ],
  },
  {
    path: "/admin/employees/:id/emails",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/admin/employees/EmployeeEmails.vue"),
      },
    ],
  },
  {
    path: "/admin/employees/:id/salaries",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/admin/employees/EmployeeSalaries.vue"),
      },
    ],
  },
  {
    path: "/admin/employees/:id/earnings",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/admin/employees/EmployeeEarnings.vue"),
      },
    ],
  },
  {
    path: "/admin/employees/:id/profile",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/admin/employees/EmployeeProfile.vue"),
      },
    ],
  },
  {
    path: "/admin/balance",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/admin/balance/BalanceList.vue"),
      },
    ],
  },

  {
    path: "/admin/settings/email-templates",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () =>
          import(
            "@/views/admin/settings/email-templates/EmailTemplatesList.vue"
          ),
      },
    ],
  },
  {
    path: "/admin/settings/email-templates/create",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () =>
          import(
            "@/views/admin/settings/email-templates/CreateEmailTemplate.vue"
          ),
      },
    ],
  },
  {
    path: "/admin/settings/configs",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () =>
          import("@/views/admin/settings/configs/ConfigsList.vue"),
      },
    ],
  },
  {
    path: "/admin/settings/email-templates/:id",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () =>
          import(
            "@/views/admin/settings/email-templates/EditEmailTemplate.vue"
          ),
      },
    ],
  },
  {
    path: "/admin/settings/email-providers",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () =>
          import(
            "@/views/admin/settings/email-providers/EmailProvidersList.vue"
          ),
      },
    ],
  },
  {
    path: "/admin/tickets",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/admin/tickets/TicketsList.vue"),
      },
    ],
  },
];
