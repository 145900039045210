import MainLayout from "@/layouts/MainLayout.vue";

export default [
  {
    path: "/tasks",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/employee/tasks/PublicTasksList.vue"),
      },
    ],
  },
  {
    path: "/tasks/:id",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/employee/tasks/PublicTasksShow.vue"),
      },
    ],
  },
  {
    path: "/my/tasks",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/employee/tasks/MyTasksList.vue"),
      },
    ],
  },
  {
    path: "/my/tasks/:id",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/employee/tasks/MyTasksShow.vue"),
      },
    ],
  },
  {
    path: "/emails",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/employee/emails/MyEmailsList.vue"),
      },
    ],
  },
  {
    path: "/emails/verify/:code",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/employee/emails/EmailsVerify.vue"),
      },
    ],
  },
  {
    path: "/my/salaries",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () =>
          import("@/views/employee/balance/EmployeeSalaries.vue"),
      },
    ],
  },
  {
    path: "/my/earnings",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () =>
          import("@/views/employee/balance/EmployeeEarnings.vue"),
      },
    ],
  },
  {
    path: "/my/tickets",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () =>
          import("@/views/employee/tickets/EmployeeTicketsList.vue"),
      },
    ],
  },
  {
    path: "/tickets/:id",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/employee/tickets/TicketShow.vue"),
      },
    ],
  },
];
