<template>
  <div id="sidebar" class="app-sidebar flex-column scroll-y">
    <div
      class="app-sidebar-header d-flex flex-stack d-md-none d-lg-flex pt-8 pb-2"
    >
      <a
        :href="user.role == 'admin' ? '/admin/dashboard' : '/tasks'"
        class="app-sidebar-logo"
      >
        <img
          src="/assets/images/logo-light.png"
          class="logo h-45px d-sm-inline app-sidebar-logo-default theme-light-show"
        />
        <img
          src="/assets/images/logo-light.png"
          class="logo h-45px theme-dark-show"
        />
      </a>

      <div
        class="app-sidebar-toggle btn btn-sm btn-icon bg-light d-lg-none btn-color-gray-700 btn-active-color-primary d-lg-flex rotate"
      >
        <i class="ki-outline ki-text-align-right rotate-180 fs-1"></i>
      </div>
    </div>
    <Notifications :data="data" v-if="user?.role == 'admin'" />
    <div class="app-sidebar-navs flex-column-fluid py-2">
      <div class="app-sidebar-wrapper hover-scroll-y my-2">
        <SidebarMenu />
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/services/api";
import SidebarMenu from "@/layouts/partials/SidebarMenu.vue";
import Notifications from "@/layouts/partials/Notifications.vue";
import { getAuthUser } from "@/stores/auth";

export default {
  name: "SidebarView",
  components: {
    SidebarMenu,
    Notifications,
  },
  props: {
    open: Boolean,
  },
  data() {
    return {
      data: {},
      user: getAuthUser(),
      isOpenSidebar: false,
    };
  },
  created() {},
  mounted() {
    this.fetchData();
    this.emitter.on("fetch-notifications", this.fetchData);
    this.emitter.on("sidebar-toggle", this.toggle);
  },
  methods: {
    async fetchData() {
      if (this.user?.role != "admin") return;
      try {
        const response = await api.get("notifications/all");
        this.data = response.data.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
  },
};
</script>

<style scoped>
[data-bs-theme="light"] .logo {
  content: url("../../../public/assets/images/logo-dark.png");
}
</style>
