<template>
  <div class="d-flex flex-column flex-root app-root">
    <div class="app-page flex-column flex-column-fluid">
      <AppHeader :user="user" />
      <!-- <AppHeader  /> -->
      <div class="app-wrapper flex-column flex-row-fluid">
        <Sidebar />
        <div class="app-main flex-column flex-row-fluid">
          <RouterView />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/services/api";
import {
  defineComponent,
  nextTick,
  onBeforeMount,
  onMounted,
  watch,
} from "vue";

import { RouterView, useRoute } from "vue-router";
import AppHeader from "@/layouts/partials/AppHeader.vue";
import Sidebar from "@/layouts/partials/Sidebar.vue";
import { setAuthUser, getAuthUser } from "@/stores/auth.js";
import events from "@/services/events";

export default defineComponent({
  name: "MainLayout",
  components: {
    AppHeader,
    Sidebar,
    RouterView,
  },
  created() {},
  mounted() {
    this.fetchData();
    events.on("update-user", this.fetchData);
  },
  data() {
    return {
      user: getAuthUser(),
    };
  },
  methods: {
    async fetchData() {
      try {
        const response = await api.get("me");
        this.user = response.data.data;
        setAuthUser(this.user);
        if (
          this.user.role == "employee" &&
          (!this.user.profile_completed || this.user.in_review)
        ) {
          this.$router.push("/me/profile");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
  },
  setup() {
    const route = useRoute();

    onBeforeMount(() => {});

    onMounted(() => {
      nextTick(() => {});
    });

    watch(
      () => route.path,
      () => {
        nextTick(() => {});
      }
    );

    return {};
  },
});
</script>
