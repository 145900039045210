<template>
  <div
    class="app-sidebar-menu-primary menu menu-column menu-rounded menu-sub-indention menu-state-bullet-primary"
  >
    <div class="menu-item mb-2">
      <div class="menu-heading text-uppercase fs-7 fw-bold">Menu</div>
      <div class="app-sidebar-separator separator"></div>
    </div>

    <div
      v-for="item in filteredMenu"
      :key="item.id"
      class="menu-item menu-accordion"
    >
      <router-link
        v-if="item.link"
        :to="item.link"
        class="menu-link"
        :class="{ active: isActive(item) }"
      >
        <span class="menu-icon">
          <div v-html="item.icon"></div>
        </span>
        <span class="menu-title">{{ item.name }}</span>
        <span class="menu-arrow" v-if="item.children?.length"></span>
      </router-link>

      <Collapse
        v-if="!item.link && item.children?.length"
        :childClass="{ 'menu-link': 1, active: isActive(item) }"
        :isChildActive="isChildActive(item.children)"
      >
        <span class="menu-icon">
          <div v-html="item.icon"></div>
        </span>
        <span class="menu-title">{{ item.name }}</span>
        <span class="menu-arrow" v-if="item.children?.length"></span>

        <template #content>
          <div class="menu-sub menu-sub-accordion show">
            <div
              v-for="child in item.children"
              :key="child.id"
              class="menu-item menu-accordion"
            >
              <router-link
                :to="child.link"
                class="menu-link"
                :class="{ active: isActive(child) }"
              >
                <span class="menu-bullet">
                  <span class="bullet bullet-dot"></span>
                </span>
                <span class="menu-title">{{ child.name }}</span>
              </router-link>
            </div>
          </div>
        </template>
      </Collapse>
    </div>
  </div>
</template>

<script>
import menu from "@/data/menu/_index";
import Collapse from "@/components/CollapseComponent.vue";

import { getAuthUser } from "@/stores/auth.js";

export default {
  name: "SidebarMenu",
  components: { Collapse },
  data() {
    return {
      menu: menu,
      activeCollapse: null,
      isOpenSidebar: false,
      user: getAuthUser(),
      collapses: menu.map((item) => item.id),
    };
  },
  computed: {
    filteredMenu() {
      return this.menu.filter(
        (item) => this.checkRoles(item) && this.checkChildren(item)
      );
    },
  },
  methods: {
    checkRoles(item) {
      return !item.roles || item.roles.includes(this.user?.role);
    },
    checkChildren(item) {
      return (
        !item.link ||
        !item.children ||
        item.children.some((child) => this.checkRoles(child))
      );
    },

    isActive(item) {
      return this.$route.path === item.link;
    },
    isChildActive(items) {
      return (items || []).find((item) => this.isActive(item));
    },
  },
};
</script>

<style scoped>
.menu-sub-accordion.show {
  flex: 0.0001 1 180px;
  transition: flex 0.3s ease;
}
</style>
