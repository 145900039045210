<template>
  <span @click.prevent="toggle" :class="childClass">
    <slot></slot>
  </span>
  <transition name="fade">
    <div v-if="isOpen">
      <slot name="content"></slot>
    </div>
  </transition>
</template>

<script>
export default {
  name: "CollapseComponent",
  props: {
    childClass: Object,
    isChildActive: Boolean,
  },
  data() {
    return {
      isOpen: this.isChildActive,
    };
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
  },
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
