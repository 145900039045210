<template>
  <button type="submit" class="btn" :class="type" :disabled="!!loading">
    <span v-if="!loading" class="indicator-label">{{ text }}</span>
    <span v-else
      >Please wait...
      <span class="spinner-border spinner-border-sm align-middle ms-2"></span
    ></span>
  </button>
</template>

<script>
export default {
  name: "ButtonComponent",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "Submit",
    },
    type: {
      type: String,
      default: "btn-primary",
    },
  },
};
</script>
