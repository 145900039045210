<template>
  <div class="app-header">
    <div
      class="app-container container-fluid d-flex align-items-stretch flex-stack"
    >
      <div
        class="d-flex align-items-center d-block d-lg-none ms-n3"
        title="Show sidebar menu"
      >
        <div
          @click="toggleDrawer()"
          @touchstart="toggleDrawer()"
          class="app-sidebar-toggle btn btn-sm btn-icon bg-light d-lg-none btn-color-gray-700 btn-active-color-primary d-lg-flex rotate mx-3"
        >
          <i class="ki-outline ki-text-align-right rotate-180 fs-1"></i>
        </div>

        <a :href="user.role == 'admin' ? '/admin/dashboard' : '/tasks'">
          <img
            alt="Logo"
            src="/assets/images/logo-light.png"
            class="logo h-30px d-block"
          />
        </a>
      </div>
      <div class="app-navbar flex-lg-grow-1" id="kt_app_header_navbar">
        <div class="app-navbar-item d-flex align-items-stretch flex-lg-grow-1">
          <div
            id="kt_header_search"
            class="header-search d-flex align-items-center w-lg-200px"
          >
            <div class="search-toggle-mobile d-flex align-items-center">
              <div class="d-flex" v-if="user.role == 'employee'">
                <span>Balance: </span>
                <span class="badge badge-primary mx-2"
                  >${{ user?.balance || 0.0 }}</span
                >
              </div>
              <div class="d-flex" v-if="user?.role == 'advertiser'">
                <span>Balance: </span>
                <span class="text-primary mx-2"
                  >${{ user?.advertiser?.balance || 0.0 }}</span
                >
                <a class="badge badge-primary" href="/advertiser/transactions"
                  >Fill Balance</a
                >
              </div>
            </div>
          </div>
        </div>

        <div class="app-navbar-item ms-1 ms-md-3">
          <Popper placement="bottom-end">
            <div
              class="cursor-pointer symbol symbol-circle symbol-35px symbol-md-45px"
            >
              <div class="symbol-label fs-3 bg-light-danger text-danger">
                {{ user?.name[0] }}
              </div>
            </div>
            <template #content>
              <div
                class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px d-block"
              >
                <div class="menu-item px-3">
                  <div class="menu-content d-flex align-items-center px-3">
                    <div class="symbol symbol-45px me-4">
                      <span
                        class="symbol-label fs-2x fw-semibold text-success bg-light-success"
                      >
                        {{ user?.name[0].toUpperCase() }}
                      </span>
                    </div>
                    <div class="d-flex flex-column">
                      <div class="fw-bold d-flex align-items-center fs-5">
                        {{ user?.name }}
                      </div>
                      <a
                        href="#"
                        class="fw-semibold text-muted text-hover-primary fs-7"
                        >{{ user?.email }}</a
                      >
                    </div>
                  </div>
                </div>
                <div class="separator my-2"></div>

                <div class="menu-item px-5">
                  <a href="/me/profile" class="menu-link px-5">My Profile</a>
                </div>

                <div class="menu-item px-5">
                  <a href="/me/password" class="menu-link px-5"
                    >Change Password</a
                  >
                </div>

                <div class="menu-item px-5">
                  <a @click="toogleThemeMode" class="menu-link px-5">
                    <span class="menu-title position-relative"
                      >Mode
                      <span
                        class="ms-5 position-absolute translate-middle-y top-50 end-0"
                      >
                        <i
                          class="ki-outline ki-night-day theme-light-show fs-2"
                        ></i>
                        <i
                          class="ki-outline ki-moon theme-dark-show fs-2"
                        ></i> </span
                    ></span>
                  </a>
                  <div
                    class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-gray-500 menu-active-bg menu-state-color fw-semibold py-4 fs-base w-150px"
                    data-kt-menu="true"
                    data-kt-element="theme-mode-menu"
                  >
                    <div class="menu-item px-3 my-0">
                      <a
                        href="#"
                        class="menu-link px-3 py-2"
                        data-kt-element="mode"
                        data-kt-value="light"
                      >
                        <span class="menu-icon" data-kt-element="icon">
                          <i class="ki-outline ki-night-day fs-2"></i>
                        </span>
                        <span class="menu-title">Light</span>
                      </a>
                    </div>
                    <div class="menu-item px-3 my-0">
                      <a
                        href="#"
                        class="menu-link px-3 py-2"
                        data-kt-element="mode"
                        data-kt-value="dark"
                      >
                        <span class="menu-icon" data-kt-element="icon">
                          <i class="ki-outline ki-moon fs-2"></i>
                        </span>
                        <span class="menu-title">Dark</span>
                      </a>
                    </div>
                    <div class="menu-item px-3 my-0">
                      <a
                        href="#"
                        class="menu-link px-3 py-2"
                        data-kt-element="mode"
                        data-kt-value="system"
                      >
                        <span class="menu-icon" data-kt-element="icon">
                          <i class="ki-outline ki-screen fs-2"></i>
                        </span>
                        <span class="menu-title">System</span>
                      </a>
                    </div>
                  </div>
                </div>

                <div class="menu-item px-5">
                  <a @click="logout" class="menu-link px-5">Sign Out</a>
                </div>
              </div>
            </template>
          </Popper>
        </div>
      </div>
      <div class="app-navbar-separator separator d-none d-lg-flex"></div>
    </div>
  </div>

  <div
    class="c-drawer py-10 px-3"
    :class="{ 'c-drawer-active': showDrawer }"
    v-if="showDrawer"
  >
    <div>
      <div class="d-flex justify-content-between align-items-center px-3 mb-10">
        <img alt="Logo" src="/assets/images/logo-light.png" class="h-30px" />
        <a href="#" @click="toggleDrawer()" @touchstart="toggleDrawer()">
          <i class="ki-outline ki-text-align-right rotate-180 fs-1"></i>
        </a>
      </div>

      <SidebarMenu />
    </div>
  </div>
</template>

<script>
import api from "@/services/api";
import { removeAuthToken, removeAuthUser } from "@/stores/auth.js";
import Popper from "vue3-popper";
import SidebarMenu from "@/layouts/partials/SidebarMenu.vue";

export default {
  name: "UsersShow",
  components: {
    SidebarMenu,
    Popper,
  },
  props: {
    user: {
      type: Object,
    },
  },
  data() {
    return {
      theme: localStorage.getItem("data-bs-theme"),
      item: {},
      password: {},
      errors: {},
      showDrawer: false,
      loading: false,
      isChangeEmailModalVisible: false,
    };
  },
  methods: {
    toggleDrawer() {
      this.showDrawer = !this.showDrawer;
    },

    toogleThemeMode() {
      let theme = localStorage.getItem("data-bs-theme");
      if (!theme || theme == "dark") {
        theme = "light";
      } else {
        theme = "dark";
      }
      localStorage.setItem("data-bs-theme", theme);
      this.$router.go(this.$router.currentRoute);
    },

    async logout() {
      try {
        await api.delete("logout");
        removeAuthToken(null);
        removeAuthUser(null);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
[data-bs-theme="light"] .logo {
  content: url("../../../public/assets/images/logo-dark.png");
}

.c-drawer {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  background-color: var(--bs-app-sidebar-base-bg-color);
  transform: translateX(-100%);
  transition: transform 0.3s ease;
  z-index: 9999;
}

.c-drawer-active {
  transform: translateX(0) !important;
}
</style>
