import MainLayout from "@/layouts/MainLayout.vue";

export default [
  {
    path: "/manager/teams",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/manager/teams/TeamsList.vue"),
      },
    ],
  },
  {
    path: "/manager/teams/:id",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/manager/teams/TeamUsers.vue"),
      },
    ],
  },
  {
    path: "/manager/teams/:id/users",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/manager/teams/TeamUsers.vue"),
      },
    ],
  },
  {
    path: "/manager/employees/:id",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () =>
          import("@/views/manager/employees/EmployeeOverview.vue"),
      },
    ],
  },
  {
    path: "/manager/employees/:id/emails",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/manager/employees/EmployeeEmails.vue"),
      },
    ],
  },
  {
    path: "/manager/employees/:id/salaries",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () =>
          import("@/views/manager/employees/EmployeeSalaries.vue"),
      },
    ],
  },
  {
    path: "/manager/employees/:id/earnings",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () =>
          import("@/views/manager/employees/EmployeeEarnings.vue"),
      },
    ],
  },
  {
    path: "/manager/employees/:id/profile",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () =>
          import("@/views/manager/employees/EmployeeProfile.vue"),
      },
    ],
  },

  {
    path: "/manager/tasks",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/manager/tasks/TasksList.vue"),
      },
    ],
  },
  {
    path: "/manager/tasks/:id",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/manager/tasks/TasksShow.vue"),
      },
    ],
  },
  {
    path: "/manager/reports",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/manager/reports/Reports.vue"),
      },
    ],
  },
  {
    path: "/manager/tickets",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/manager/tickets/TicketsList.vue"),
      },
    ],
  },
  {
    path: "/manager/tickets/:id",
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/manager/tickets/TicketShow.vue"),
      },
    ],
  },
];
