<template>
  <div class="app-sidebar-menu-secondary menu menu-rounded menu-column mt-6">
    <div class="menu-item mb-2">
      <div class="menu-heading text-uppercase fs-7 fw-bold">Quick Actions</div>
      <div class="app-sidebar-separator separator"></div>
    </div>
    <div class="menu-item">
      <a
        class="menu-link"
        :class="{ active: isActive('/admin/moderation/advertisers') }"
        href="/admin/moderation/advertisers"
      >
        <span class="menu-icon">
          <span
            class="bullet bullet-dot"
            :class="{
              'bg-primary': !data.advertisers,
              'bg-danger': data.advertisers,
            }"
          ></span>
        </span>
        <span class="menu-title"
          >New Advertisers ({{ data.advertisers || 0 }})</span
        >
      </a>
    </div>
    <div class="menu-item">
      <a
        class="menu-link"
        :class="{ active: isActive('/admin/employees/unassigned') }"
        href="/admin/employees/unassigned"
      >
        <span class="menu-icon">
          <span
            class="bullet bullet-dot"
            :class="{
              'bg-primary': !data.employees,
              'bg-danger': data.employees,
            }"
          ></span>
        </span>
        <span class="menu-title"
          >New Employees ({{ data.employees || 0 }})</span
        >
      </a>
    </div>
    <div class="menu-item">
      <a
        class="menu-link"
        :class="{ active: isActive('/admin/review/tasks') }"
        href="/admin/review/tasks"
      >
        <span class="menu-icon">
          <span
            class="bullet bullet-dot"
            :class="{
              'bg-primary': !data.tasks,
              'bg-danger': data.tasks,
            }"
          ></span>
        </span>
        <span class="menu-title">New Tasks ({{ data.tasks || 0 }})</span>
      </a>
    </div>
    <div class="menu-item">
      <a
        class="menu-link"
        :class="{ active: isActive('/admin/user-tasks') }"
        href="/admin/user-tasks"
      >
        <span class="menu-icon">
          <span
            class="bullet bullet-dot"
            :class="{
              'bg-primary': !data.user_tasks,
              'bg-danger': data.user_tasks,
            }"
          ></span>
        </span>
        <span class="menu-title">User Tasks ({{ data.user_tasks || 0 }})</span>
      </a>
    </div>
    <div class="menu-item">
      <a
        class="menu-link"
        :class="{ active: isActive('/admin/notification/salaries') }"
        href="/admin/notification/salaries"
      >
        <span class="menu-icon">
          <span
            class="bullet bullet-dot"
            :class="{
              'bg-primary': !data.salary,
              'bg-danger': data.salary,
            }"
          ></span>
        </span>
        <span class="menu-title">Salary ({{ data.salary || 0 }})</span>
      </a>
    </div>

    <div class="menu-item">
      <a
        class="menu-link"
        :class="{ active: isActive('/admin/tickets') }"
        href="/admin/tickets"
      >
        <span class="menu-icon">
          <span
            class="bullet bullet-dot"
            :class="{
              'bg-primary': !data.support,
              'bg-danger': data.support,
            }"
          ></span>
        </span>
        <span class="menu-title">Support ({{ data.support || 0 }})</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "TeamsView",
  props: {
    data: Object,
  },

  methods: {
    isActive(link) {
      return this.$route.path === link;
    },
  },
};
</script>

<style scoped></style>
