export default [
  {
    id: "e_2",
    name: "Tasks",
    link: "/manager/tasks",
    icon: '<i class="fa fa-tasks fs-2"></i>',
    roles: ["manager"],
  },

  {
    id: "e_3",
    name: "Teams",
    link: "/manager/teams",
    icon: '<i class="fa fa-envelope fs-2"></i>',
    roles: ["manager"],
  },
  {
    id: "e_4",
    name: "Reports",
    link: "/manager/reports",
    icon: '<i class="fa fa-line-chart fs-2"></i>',
    roles: ["manager"],
  },
  {
    id: "m_5",
    name: "Support",
    link: "/manager/tickets",
    icon: '<i class="fa fa-question-circle fs-2"></i>',
    roles: ["manager"],
  },
];
